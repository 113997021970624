// mantain abc order

export const ICONS = {
  addIconDav: 'https://alfi-images.s3.us-east-2.amazonaws.com/iconAddDav.png',
  avaTradeIcon: 'https://alfi-images.s3.us-east-2.amazonaws.com/products/avatrade-modal.jpg',
  doneIcon: 'https://alfi-images.s3.us-east-2.amazonaws.com/doneGreen.png',
  doneIconDav: 'https://alfi-images.s3.us-east-2.amazonaws.com/iconDoneDav.png',
  editIcon: 'https://alfi-images.s3.us-east-2.amazonaws.com/lapiz.png',
  errorIconRed: 'https://alfi-images.s3.us-east-2.amazonaws.com/errorIconRed.png',
  etoroIcon: 'https://alfi-images.s3.us-east-2.amazonaws.com/products/etoro-modal.jpg',
  exitIcon: 'https://alfi-images.s3.us-east-2.amazonaws.com/x.png',
  exitIconWhite: 'https://alfi-images.s3.us-east-2.amazonaws.com/exitIcon.png',
  exitIconDav: 'https://alfi-images.s3.us-east-2.amazonaws.com/iconExitDav.png',
  iconAlfiBank: 'https://alfi-images.s3.us-east-2.amazonaws.com/iconMarketplace.png',
  iconAlfiBankActive: 'https://alfi-images.s3.us-east-2.amazonaws.com/Menu_TARJETA.png',
  iconAlfiBankActiveDav: 'https://alfi-images.s3.us-east-2.amazonaws.com/TARJETA+ROJO.png',
  iconAsobPointInfo: 'https://alfi-images.s3.us-east-2.amazonaws.com/iconAsobPointInfo.png',
  iconBoy: 'https://alfi-images.s3.us-east-2.amazonaws.com/boyIcon.png',
  iconBrokenHeart: 'https://alfi-images.s3.us-east-2.amazonaws.com/iconBrokenHeart.png',
  iconBrokenHeartWhite: 'https://alfi-images.s3.us-east-2.amazonaws.com/iconBrokenHeartWhite.png',
  iconClasses: 'https://alfi-images.s3.us-east-2.amazonaws.com/iconClasses.png',
  iconClassesActive: 'https://alfi-images.s3.us-east-2.amazonaws.com/Menu_LIBRO.png',
  iconClassesActiveDav: 'https://alfi-images.s3.us-east-2.amazonaws.com/CLASES+ROJO.png',
  iconCone: 'https://alfi-images.s3.us-east-2.amazonaws.com/iconCone.png',
  iconComment: 'https://alfi-images.s3.us-east-2.amazonaws.com/iconComment.png',
  iconConfettiLeft: 'https://alfi-images.s3.us-east-2.amazonaws.com/confeti-left.png',
  iconConfettiRight: 'https://alfi-images.s3.us-east-2.amazonaws.com/confeti-right.png',
  iconDiploma: 'https://alfi-images.s3.us-east-2.amazonaws.com/iconDiploma.png',
  iconDrum: 'https://alfi-images.s3.us-east-2.amazonaws.com/iconDrum.png',
  iconExcel: 'https://alfi-images.s3.us-east-2.amazonaws.com/iconExcel.png',
  iconGroups: 'https://alfi-images.s3.us-east-2.amazonaws.com/iconGroups.png',
  iconGroupsActive: 'https://alfi-images.s3.us-east-2.amazonaws.com/Menu_GRUPOS.png',
  iconGroupsActiveDav: 'https://alfi-images.s3.us-east-2.amazonaws.com/GRUPOS+ROJO.png',
  iconGuitar: 'https://alfi-images.s3.us-east-2.amazonaws.com/iconGuitar.png',
  iconHome: 'https://alfi-images.s3.us-east-2.amazonaws.com/home.png',
  iconHomeActive: 'https://alfi-images.s3.us-east-2.amazonaws.com/iconHome.png',
  iconHomeActiveDav: 'https://alfi-images.s3.us-east-2.amazonaws.com/HOME+ROJO.png',
  iconLike: 'https://alfi-images.s3.us-east-2.amazonaws.com/iconLike.png',
  iconLikeGreen: 'https://alfi-images.s3.us-east-2.amazonaws.com/iconLikeGreen.png',
  iconLink: 'https://alfi-images.s3.us-east-2.amazonaws.com/iconLink.png',
  iconMedal: 'https://alfi-images.s3.us-east-2.amazonaws.com/iconStar.png',
  iconNLives: 'https://alfi-images.s3.us-east-2.amazonaws.com/iconNLives.png',
  iconRanking: 'https://alfi-images.s3.us-east-2.amazonaws.com/iconRanking.png',
  iconRankingActive: 'https://alfi-images.s3.us-east-2.amazonaws.com/Menu_RANKING.png',
  iconRankingActiveDav: 'https://alfi-images.s3.us-east-2.amazonaws.com/RANKING+ROJO.png',
  iconPerson: 'https://alfi-images.s3.us-east-2.amazonaws.com/CLASESAVE.png',
  iconPlay: 'https://alfi-images.s3.us-east-2.amazonaws.com/iconPlay.png',
  iconPriceTag: 'https://alfi-images.s3.us-east-2.amazonaws.com/iconPriceTag.png',
  iconRitmoCupBook: 'https://alfi-images.s3.us-east-2.amazonaws.com/iconRitmoCupBook.png',
  iconSearch: 'https://alfi-images.s3.us-east-2.amazonaws.com/iconSearch.png',
  iconTag: 'https://alfi-images.s3.us-east-2.amazonaws.com/iconTag.png',
  iconTest: 'https://alfi-images.s3.us-east-2.amazonaws.com/fileIcon.png',
  iconThreeDots: 'https://alfi-images.s3.us-east-2.amazonaws.com/iconThreeDots.png',
  iconTickDone: 'https://alfi-images.s3.us-east-2.amazonaws.com/iconTick.png',
  iconTickDoneDav: 'https://alfi-images.s3.us-east-2.amazonaws.com/iconTickDav.png',
  indicatorPend: 'https://alfi-images.s3.us-east-2.amazonaws.com/relojmkp.png',
  indicatorSent: 'https://alfi-images.s3.us-east-2.amazonaws.com/okmkp.png',
  infoIcon: 'https://alfi-images.s3.us-east-2.amazonaws.com/info2.5.png',
  infoIconWhite: 'https://alfi-images.s3.us-east-2.amazonaws.com/infoIconWhite.png',
  leftArrowIcon: 'https://alfi-images.s3.us-east-2.amazonaws.com/leftArrowIcon.png',
  lockIcon: 'https://alfi-images.s3.us-east-2.amazonaws.com/lock.png',
  medalIcon: 'https://alfi-images.s3.us-east-2.amazonaws.com/medalIcon.png',
  moneyBagIcon: 'https://alfi-images.s3.us-east-2.amazonaws.com/money+bag.png',
  rightArrowIconWhite: 'https://alfi-images.s3.us-east-2.amazonaws.com/rightArrowIcon.png',
  rocketIcon: 'https://alfi-images.s3.us-east-2.amazonaws.com/rocketIcon.png',
  toroBannerIcon: 'https://alfi-images.s3.us-east-2.amazonaws.com/torobanner.png',
};
