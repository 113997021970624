exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".styles__alertContainer__sUDns {\n  display: flex;\n  flex-direction: column;\n  justify-content: space-evenly;\n  align-items: center;\n  border-radius: 15px;\n  width: 288px;\n  min-height: 380px;\n  height: 100%;\n  padding: 20px;\n  text-align: center; }\n  @media only screen and (min-width: 640px) {\n    .styles__alertContainer__sUDns {\n      width: 592px;\n      height: 100%;\n      min-height: 475px; } }\n  @media only screen and (max-width: 639px) {\n    .styles__alertContainer__sUDns {\n      min-height: 247px; } }\n\n.styles__dialogContainer__1oaUK {\n  align-items: center;\n  display: flex;\n  justify-content: center; }\n", ""]);

// exports
exports.locals = {
	"alertContainer": "styles__alertContainer__sUDns",
	"dialogContainer": "styles__dialogContainer__1oaUK"
};