export const IMAGES = {
  bg: 'https://alfi-asobancaria-pics.s3.us-east-2.amazonaws.com/bg_Aso.png',
  bgAsob: 'https://alfi-asobancaria.s3.us-east-2.amazonaws.com/FONDOINICIO+(1).png',
  bgAsobRed: 'https://alfi-asobancaria.s3.us-east-2.amazonaws.com/FONDO+DE+DASHBOARD+(1).png',
  logoAsob: 'https://alfi-images.s3.us-east-2.amazonaws.com/logoAsob.png',
  bgAsob2: 'https://alfi-asobancaria.s3.us-east-2.amazonaws.com/AULAS+(1).png',
  bgAsob3: 'https://alfi-images.s3.us-east-2.amazonaws.com/bgAsob3.png',
  bgAsob4: 'https://alfi-images.s3.us-east-2.amazonaws.com/bgAsob4.png',
  iconFirework: 'https://alfi-images.s3.us-east-2.amazonaws.com/iconAsobFirework.png',
  imgAntropologist: 'https://alfi-images.s3.us-east-2.amazonaws.com/imgAntropologistAsob.png',
  imgAsobMap1: 'https://alfi-images.s3.us-east-2.amazonaws.com/imgAsobMap1.png',
  imgAsobMap2: 'https://alfi-images.s3.us-east-2.amazonaws.com/imgAsobMap2.png',
  imgAsobMission3: 'https://alfi-images.s3.us-east-2.amazonaws.com/imgAsobMission3.png',
  imgAsobMission4: 'https://alfi-images.s3.us-east-2.amazonaws.com/imgAsobMission4.png',
  imgAsobMission5: 'https://alfi-images.s3.us-east-2.amazonaws.com/imgAsobMission5.png',
  imgAsobMission6: 'https://alfi-images.s3.us-east-2.amazonaws.com/imgAsobMission6.png',
  imgArtist: 'https://alfi-images.s3.us-east-2.amazonaws.com/imgArtistAsob.png',
  imgCalendar: 'https://alfi-images.s3.us-east-2.amazonaws.com/imgAsobCalendar.png',
  imgCharacter: 'https://alfi-images.s3.us-east-2.amazonaws.com/imgAsobCharacter.png',
  imgChest: 'https://alfi-images.s3.us-east-2.amazonaws.com/imgAsobChest.png',
  imgCrossroads: 'https://alfi-images.s3.us-east-2.amazonaws.com/imgAsobCrossroads.png',
  imgEngineer: 'https://alfi-images.s3.us-east-2.amazonaws.com/imgEngineerAsob.png',
  imgGem: 'https://alfi-images.s3.us-east-2.amazonaws.com/imgAsobGem.png',
  imgInvestor: 'https://alfi-images.s3.us-east-2.amazonaws.com/imgInvestorAsob.png',
  imgJournalist: 'https://alfi-images.s3.us-east-2.amazonaws.com/imgJournalistAsob.png',
  imgScientist: 'https://alfi-images.s3.us-east-2.amazonaws.com/imgScientistAsob.png',
  imgMission1: 'https://alfi-images.s3.us-east-2.amazonaws.com/iconAsobMission1.png',
  imgMission2: 'https://alfi-images.s3.us-east-2.amazonaws.com/iconAsobMission2.png',
  imgMission3: 'https://alfi-images.s3.us-east-2.amazonaws.com/iconAsobMission3.png',
  imgMission4: 'https://alfi-images.s3.us-east-2.amazonaws.com/iconAsobMission4.png',
  imgNewPanguea: 'https://alfi-images.s3.us-east-2.amazonaws.com/imgNewPanguea.png',
  imgTicket900: 'https://alfi-images.s3.us-east-2.amazonaws.com/imgTicket1000.png',
  iconArrowMissionsFlow1: 'https://alfi-images.s3.us-east-2.amazonaws.com/arrowAsobMission.png',
  iconArrowMissionsFlow2: 'https://alfi-images.s3.us-east-2.amazonaws.com/arrowLongAsobMission.png',
  iconCopy: 'https://alfi-images.s3.us-east-2.amazonaws.com/iconCopy.png',
  imgEye: 'https://alfi-images.s3.us-east-2.amazonaws.com/imgAsobEye.png',
  iconResourceS: 'https://alfi-images.s3.us-east-2.amazonaws.com/iconResourseS.png',
  iconResourceA: 'https://alfi-images.s3.us-east-2.amazonaws.com/iconResourseA.png',
  iconResourceB: 'https://alfi-images.s3.us-east-2.amazonaws.com/iconResourseB.png',
  iconResourceE: 'https://alfi-images.s3.us-east-2.amazonaws.com/iconResourseE.png',
  iconResourceR: 'https://alfi-images.s3.us-east-2.amazonaws.com/iconResourseR.png',
  iconTicket: 'https://alfi-images.s3.us-east-2.amazonaws.com/imgTicket.png',
  security: 'https://cdn-icons-png.flaticon.com/512/2592/2592258.png',
};

export const IMGSKILLS = {
  ear: 'https://asobancaria-images.s3.us-east-2.amazonaws.com/ear.png',
  lupa: 'https://asobancaria-images.s3.us-east-2.amazonaws.com/lupa.png',
  chart: 'https://asobancaria-images.s3.us-east-2.amazonaws.com/chart.png',
  avatar01: 'https://asobancaria-images.s3.us-east-2.amazonaws.com/412520507-avatar_01.png',
  avatar02: 'https://asobancaria-images.s3.us-east-2.amazonaws.com/412520515-avatar_02.png',
  avatar03: 'https://asobancaria-images.s3.us-east-2.amazonaws.com/412520523-avatar_03.png',
  avatar04: 'https://asobancaria-images.s3.us-east-2.amazonaws.com/412520529-avatar_04.png',
  avatar05: 'https://asobancaria-images.s3.us-east-2.amazonaws.com/412520535-avatar_05.png',
  avatar06: 'https://asobancaria-images.s3.us-east-2.amazonaws.com/412520543-avatar_06.png',
  avatar07: 'https://asobancaria-images.s3.us-east-2.amazonaws.com/412520553-avatar_07.png',
  avatar08: 'https://asobancaria-images.s3.us-east-2.amazonaws.com/412781514-avatar_08.png',
  avatar09: 'https://asobancaria-images.s3.us-east-2.amazonaws.com/412781524-avatar_09.png',
  avatar10: 'https://asobancaria-images.s3.us-east-2.amazonaws.com/412781526-avatar_10.png',
  avatar11: 'https://asobancaria-images.s3.us-east-2.amazonaws.com/412781533-avatar_11.png',
  avatar12: 'https://asobancaria-images.s3.us-east-2.amazonaws.com/412781542-avatar_12.png',
  avatar13: 'https://asobancaria-images.s3.us-east-2.amazonaws.com/412781507-avatar_13.png',
};
