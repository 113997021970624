import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import styles from './styles.scss';

function Button({
  id,
  label,
  onClick,
  className,
  customIconStyle,
  customIconLeftStyle,
  customStyle,
  data,
  to,
  iconLeft,
  iconRight,
  onIconLeftClick,
  value,
  disabled,
  style,
  type,
}) {
  const CustomButton = to ? Link : 'button';
  const dataProps = {
    ...(data && { 'data-values': data }),
    ...(onClick && { onClick }),
    ...(to && { to }),
  };
  // let stylez = '';
  // const styleArray = className && className.split(' ');
  // for (let i = 0; i < styleArray.length; i += 1) {
  //   stylez += `styles[${styleArray[i]}] `;
  //   console.log(stylez);
  // }

  return (
    <CustomButton
      className={`${styles.button} ${className && styles[className]} ${customStyle && customStyle}`}
      id={id}
      value={value}
      style={style}
      disabled={disabled}
      {...dataProps}
      type={type}
    >
      {iconLeft
      && (
      <span onClick={onIconLeftClick} role="button" tabIndex={0}>
        <img src={iconLeft} alt="icon" className={`${styles.iconLeft} ${customIconLeftStyle}`} id={id} />
      </span>
      )}
      {label}
      {iconRight && <img src={iconRight} alt="icon" className={`${styles.iconRight} ${customIconStyle}`} />}
    </CustomButton>
  );
}

Button.defaultProps = {
  id: '',
  className: '',
  customStyle: '',
  onClick: false,
};

Button.propTypes = {
  label: PropTypes.string.isRequired,
  onClick: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
  customStyle: PropTypes.string,
};

export default Button;
