exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".styles__dialogContainer__3uJur {\n  min-height: 100vh;\n  max-height: 100vh;\n  position: absolute;\n  width: 100%;\n  z-index: 11;\n  align-items: center;\n  justify-content: center;\n  overflow: scroll; }\n\n.styles__dialogContainer__3uJur::-webkit-scrollbar {\n  display: none; }\n", ""]);

// exports
exports.locals = {
	"dialogContainer": "styles__dialogContainer__3uJur"
};