import React, { useEffect } from 'react';
import { createPortal } from 'react-dom';

import styles from './styles.scss';

function Dialog({
  withoutAnimation = false,
  children,
  state,
  bgColor = 'rgba(124, 124, 124, 0.8)',
  bgImg,
  customClassName,
}) {
  const domNode = window.document.querySelector('#dialog-portal');
  let portalClassName = withoutAnimation
    ? styles.dialogContainer
    : `zoomInUp ${styles.dialogContainer}`;
  if (customClassName) {
    portalClassName += ` ${customClassName}`;
  }
  useEffect(() => {
    const appNode = window.document.querySelector('#root');
    if (state) {
      appNode.style.setProperty('transition', 'filter 0.75s ease-in');
      appNode.style.setProperty('filter', 'blur(5px)');
      window.document.body.style.setProperty('overflow', 'hidden');
      window.scrollTo(0, 0);
    }
    return () => {
      appNode.style.removeProperty('transition');
      appNode.style.removeProperty('filter');
      window.document.body.style.removeProperty('overflow');
    };
  }, [state]);

  return createPortal(
    state && (
      <div
        style={{
          backgroundColor: bgColor,
          backgroundImage: bgImg && `url(${bgImg})`,
          backgroundSize: 'cover',
        }}
        className={portalClassName}
      >
        {children}
      </div>
    ),
    domNode,
  );
}

export default Dialog;
