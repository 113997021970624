import {
  LOGIN,
  GET_USER,
  LOGOUT,
  CREATE_USER,
  RECOVER_PASSWORD,
  CHANGE_PASSWORD,
  CLEAN_PROFILE_UPDATE,
  GET_GROUPS,
  REMOVE_GROUP,
  GET_GROUP,
  JOIN_GROUP,
  LEAVE_GROUP,
  SEARCH_USER,
  SAVE_GROUP,
  GET_COURSES,
  GET_COURSE,
  GET_COURSE_TEST,
  CHECK_LESSON,
  GET_BUDGET,
  SAVE_BUDGET,
  GET_MISSIONS,
  GET_USER_MISSIONS,
  GET_CATEGORIES,
  GET_USER_GROUP_TESTS,
  GET_COURSES_SHORT,
  GET_NEW_PROFILE,
  DELETE_BUDGET,
  GET_PREMIUM_GROUP_DETAIL,
  GET_USER_LEVEL_PROGRESS,
  UPDATE_USER_PROFILE,
  SEARCH_USERS,
  FILL_USER_MISSIONS,
  GET_COUNTRIES,
  GET_GROUPS_SEARCH_LIST,
  GET_METAS,
  GET_MOTIVES,
  POST_PROFILE,
  UPDATE_METAS,
  UPDATE_MOTIVES,
} from '@constants/actionNames';
import {
  COMPLETE_MISSION,
  GET_ADMIN_GROUP_DATA,
  GET_CURRENT_STORY,
  GET_DASHBOARD,
  GET_DOCENTS_BY_PROVINCE,
  GET_DOCENTS_BY_REGION,
  GET_PROVINCES,
  GET_RANKING,
  GET_REGIONS,
  GET_SCHOOLS,
  GET_STUDENTS_BY_PROVINCE,
  GET_STUDENTS_BY_REGION,
  SEARCH_JOIN_GROUP,
  SET_AVATAR,
  SET_COMPLETED_TUTORIALS,
  SET_ROL,
  SET_SCORE,
  UPLOAD_PICTURE,
} from '../../constants/actionNames';

const initialState = {
  // Pass recover
  changePasswordData: false,
  changePasswordError: false,
  changePasswordLoading: false,
  recoverPasswordData: false,
  recoverPasswordError: false,
  recoverPasswordLoading: false,

  // User login
  loginData: false,
  loginLoading: false,
  loginError: false,

  // User creation
  createUserData: false,
  createUserLoading: false,
  createUserError: false,
  fillUserMissionsData: false,
  fillUserMissionsLoading: false,
  fillUserMissionsError: false,
  newProfileData: false,
  newProfileLoading: false,
  newProfileError: false,
  metasData: false,
  metasLoading: false,
  metasError: false,
  motivesData: false,
  motivesLoading: false,
  motivesError: false,
  countriesData: false,
  countriesLoading: false,
  countriesError: false,
  profileData: false, // response of PROFILE_POST
  profileLoading: false,
  profileError: false,
  regionsData: false,
  regionsLoading: false,
  regionsError: false,
  provincesData: false,
  provincesLoading: false,
  provincesError: false,
  schoolsData: false,
  schoolsLoading: false,
  schoolsError: false,

  // Home section
  userData: false,
  userLoading: false,
  userError: false,
  userLevelProgressData: false,
  userLevelProgressLoading: false,
  userLevelProgressError: false,
  missionsData: false,
  missionsLoading: false,
  missionsError: false,
  userMissionsData: false,
  userMissionsLoading: false,
  userMissionsError: false,
  categoriesData: false,
  categoriesDataLoading: false,
  categoriesError: false,
  coursesData: false,
  coursesLoading: false,
  coursesError: false,

  // Budget section
  budgetData: false,
  budgetLoading: false,
  budgetError: false,
  saveBudgetData: false,
  saveBudgetLoading: false,
  saveBudgetError: false,

  // Courses section
  coursesTestData: false,
  coursesTestLoading: false,
  coursesTestError: false,
  checkLessonData: false,
  checkLessonLoading: false,
  checkLessonError: false,

  // Groups section
  completeMissionData: false,
  groupsData: false,
  groupsLoading: false,
  groupsError: false,
  premiumGroupData: false,
  premiumGroupLoading: false,
  premiumGroupError: false,
  groupData: false,
  groupLoading: false,
  groupError: false,
  userTestGroupData: false,
  userTestGroupLoading: false,
  userTestGroupError: false,
  coursesShortData: false,
  coursesShortLoading: false,
  coursesShortError: false,
  joinGroupData: false,
  joinGroupLoading: false,
  joinGroupError: false,
  searchUsersData: false,
  searchUsersLoading: false,
  searchUsersError: false,
  saveGroupData: false,
  saveGroupLoading: false,
  saveGroupError: false,
  removeGroupData: false,
  removeGroupLoading: false,
  removeGroupError: false,
  searchUserData: false,
  searchUserLoading: false,
  searchUserError: false,
  sendInvitationData: false,
  sendInvitationLoading: false,
  sendInvitationError: false,
  adminGroupData: false,
  adminGroupLoading: false,
  adminGroupError: false,
  searchJoinGroupData: false,
  searchJoinGroupLoading: false,
  searchJoinGroupError: false,

  // Common
  uploadPictureData: false,
  setAvatarData: false,

  // To implement
  groupsSearchListData: false,
  groupsSearchListLoading: false,
  groupsSearchListError: false,

  // Ranking
  rankingError: false,
  rankingData: { top: [], normal: [] },

  // Aso
  currentStoryData: false,
  currentStoryLoading: false,
  currentStoryError: false,
  setCompletedTutorialsData: false,

  // Control
  docentsByRegionData: false,
  docentsByProvinceData: false,
  studentsByRegionData: false,
  studentsByProvinceData: false,
  getDashboardData: { notemptystudents: [], notemptyteachers: [], total: false },
};

const authReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    // Pass recover
    case RECOVER_PASSWORD:
      return { ...state, ...payload };
    case CHANGE_PASSWORD:
      return { ...state, ...payload };

    // User login
    case LOGIN:
      return { ...state, ...payload };

    // User creation
    case CREATE_USER:
      return { ...state, ...payload };
    case FILL_USER_MISSIONS:
      return { ...state, ...payload };
    case GET_NEW_PROFILE:
      return { ...state, ...payload };
    case GET_MOTIVES:
      return { ...state, ...payload };
    case GET_METAS:
      return { ...state, ...payload };
    case GET_COUNTRIES:
      return { ...state, ...payload };
    case POST_PROFILE:
      return { ...state, ...payload };
    case UPDATE_MOTIVES:
      return { ...state, motivesData: payload };
    case UPDATE_METAS:
      return { ...state, metasData: payload };
    case GET_REGIONS:
      return { ...state, ...payload };
    case GET_PROVINCES:
      return { ...state, ...payload };
    case GET_SCHOOLS:
      return { ...state, ...payload };

    // Home
    case GET_USER:
      return { ...state, ...payload };
    case GET_USER_LEVEL_PROGRESS:
      return { ...state, ...payload };
    case GET_MISSIONS:
      return { ...state, ...payload };
    case GET_USER_MISSIONS:
      return { ...state, ...payload };
    case GET_CATEGORIES:
      return { ...state, ...payload };
    case GET_COURSES:
      return { ...state, ...payload };
    case LOGOUT:
      return { ...state, ...payload };

    // Budget section
    case GET_BUDGET:
      return { ...state, ...payload };
    case SAVE_BUDGET:
      return { ...state, ...payload };
    case DELETE_BUDGET:
      return { ...state, ...payload };

    // Courses section
    case GET_COURSE:
      return { ...state, ...payload };
    case GET_COURSE_TEST:
      return { ...state, ...payload };
    case CHECK_LESSON:
      return { ...state, ...payload };

    // Groups section
    case GET_GROUPS:
      return { ...state, ...payload };
    case GET_GROUP:
      return { ...state, ...payload };
    case GET_PREMIUM_GROUP_DETAIL:
      return { ...state, ...payload };
    case GET_USER_GROUP_TESTS:
      return { ...state, ...payload };
    case GET_COURSES_SHORT:
      return { ...state, ...payload };
    case JOIN_GROUP:
      return { ...state, ...payload, userData: { ...state.userData, numMyGroups: state.userData.numGroupsJoined + 1 } };
    case LEAVE_GROUP:
      return { ...state, ...payload, userData: { ...state.userData, numMyGroups: state.userData.numGroupsJoined - 1 } };
    case SAVE_GROUP:
      return { ...state, ...payload, userData: { ...state.userData, numMyGroups: payload.saveGroupData ? state.userData.numMyGroups + 1 : state.userData.numMyGroups } };
    case REMOVE_GROUP:
      return { ...state, ...payload, userData: { ...state.userData, numMyGroups: payload.removeGroupData ? state.userData.numMyGroups - 1 : state.userData.numMyGroups } };
    case SEARCH_USERS:
      return { ...state, ...payload };
    case SEARCH_USER:
      return { ...state, ...payload };
    case GET_ADMIN_GROUP_DATA:
      return { ...state, ...payload };
    case SEARCH_JOIN_GROUP:
      return { ...state, ...payload };

    // Common
    case CLEAN_PROFILE_UPDATE:
      return { ...state, ...payload };
    case SET_ROL:
      return { ...state, ...payload };
    case UPLOAD_PICTURE: {
      if (payload.uploadPictureData) {
        const newState = { ...state };
        newState.userData.profilePicture = payload.uploadPictureData.url;
        return { ...newState, ...payload };
      }
      return { ...state, ...payload };
    }
    case SET_AVATAR: {
      if (payload.setAvatarData) {
        const newState = { ...state };
        newState.userData.profilePicture = payload.setAvatarData;
        let found = null;
        found = newState.rankingData.top.find(element => element.id === newState.userData.id);
        if (found) {
          found.profilePicture = payload.setAvatarData;
          found.userPicture = payload.setAvatarData;
        } else {
          found = newState.rankingData.normal.find(element => element.id === newState.userData.id);
          if (found) {
            found.profilePicture = payload.setAvatarData;
            found.userPicture = payload.setAvatarData;
          }
        }
        return { ...newState, ...payload };
      }
      return { ...state, ...payload };
    }

    // To implement
    case UPDATE_USER_PROFILE:
      return { ...state, ...payload };
    case GET_GROUPS_SEARCH_LIST:
      return { ...state, ...payload };

    // Missions
    case COMPLETE_MISSION:
      return { ...state, ...payload };
    case SET_COMPLETED_TUTORIALS:
      if (payload.setCompletedTutorialsData) {
        const newState = { ...state };
        newState.userData.completed_stories = payload.setCompletedTutorialsData;
        return { ...newState, ...payload };
      }
      return { ...state, ...payload };
    // Asobancaria
    case SET_SCORE:
      return { ...state, ...payload };
    case GET_CURRENT_STORY:
      return { ...state, ...payload };

    // Ranking
    case GET_RANKING:
      return { ...state, ...payload };

    // Control
    case GET_DOCENTS_BY_REGION:
      return { ...state, ...payload };
    case GET_DOCENTS_BY_PROVINCE:
      return { ...state, ...payload };
    case GET_STUDENTS_BY_REGION:
      return { ...state, ...payload };
    case GET_STUDENTS_BY_PROVINCE:
      return { ...state, ...payload };
    case GET_DASHBOARD:
      return { ...state, ...payload };
    default:
      return state;
  }
};

export default authReducer;
