import React from 'react';
import avatarDefaultImg from '@assets/avatar-default.png';
import { Link } from 'react-router-dom';
import ROUTES from '@constants/routes';
import { ICONS } from '@assets/images/icons';

import styles from './styles.scss';

function Avatar({ customStyle, edit, avatarImg }) {
  return (
    <div className={`${customStyle || 'avatar'}`}>
      <img
        src={avatarImg || avatarDefaultImg}
        alt="Mercado de Capitales"
      />
      {edit && (
        <Link to={ROUTES.PROFILE_EDIT}>
          <img src={ICONS.editIcon} alt="Edit" className={styles.edit} />
        </Link>
      )}
    </div>
  );
}

export default Avatar;
