import {
  GET_RANKING,
} from '@constants/actionNames';

const initialState = {
  rankingError: false,
  rankingData: { top: [], normal: [] },
};

const postReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_RANKING:
      return { ...state, ...payload };
    default:
      return state;
  }
};

export default postReducer;
