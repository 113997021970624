import React from 'react';
import Dialog from '@components/complex/Dialog';

import styles from './styles.scss';

function SimpleAlert({
  children,
  state,
  customClassName = '',
  withoutAnimation = false,
  bgColor = 'rgba(0, 0, 0, 0.8)',
  color = 'transparent',
}) {
  return (
    <Dialog
      bgColor={bgColor}
      customClassName={styles.dialogContainer}
      state={state}
      withoutAnimation={withoutAnimation}
    >
      <div className={`${styles.alertContainer} ${customClassName}`} style={{ backgroundColor: color }}>{children}</div>
    </Dialog>
  );
}

export default SimpleAlert;
