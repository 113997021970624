export const CREATE_USER = 'CREATE_USER';
export const DELETE_BUDGET = 'DELETE_BUDGET';
export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const GET_USERS = 'GET_USERS';
export const GET_POSTS = 'GET_POSTS';
export const GET_COUNTRIES = 'GET_COUNTRIES';
export const POST_EMAIL_MARKETPLACE = 'POST_EMAIL_MARKETPLACE';
export const POST_PROFILE = 'POST_PROFILE';
// -- USER REGISTER
export const GET_REGIONS = 'GET_REGIONS';
export const GET_PROVINCES = 'GET_PROVINCES';
export const GET_SCHOOLS = 'GET_SCHOOLS';

export const POST_ANSWERS = 'POST_ANSWERS';
export const GET_TEST = 'GET_TEST';
export const GET_TEST_RESULT = 'GET_TEST_RESULT';
export const GET_USER = 'GET_USER';
export const POST_TEST_QUESTION = 'POST_TEST_QUESTION';
export const SET_PROFILE_MOTIVES = 'SET_PROFILE_MOTIVES';
export const UPDATE_USER = 'UPDATE_USER';
export const UPDATE_PROFILE = 'UPDATE_PROFILE';
export const RECOVER_PASSWORD = 'RECOVER_PASSWORD';
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
export const GET_ALL_LEVELS = 'GET_ALL_LEVELS';
export const GET_ALL_PRODUCTS = 'GET_ALL_PRODUCTS';
export const GET_LEVELS = 'GET_LEVELS';
export const CLEAN_PROFILE_UPDATE = 'CLEAN_PROFILE_UPDATE';
export const POST_NEXT_LEVEL = 'POST_NEXT_LEVEL';
export const CLEAN_TEST_RESULT_DATA = 'CLEAN_TEST_RESULT_DATA';
export const UPLOAD_PICTURE = 'UPLOAD_PICTURE';
export const SET_COMPLETED_TUTORIALS = 'SET_COMPLETED_TUTORIALS';
export const SET_AVATAR = 'SET_AVATAR';
export const SEND_EMAIL_PRODUCT = 'SEND_EMAIL_PRODUCT';
export const DELETE_ACCOUNT = 'DELETE_ACCOUNT';
export const GET_MISSION = 'GET_MISSION';
// export const COMPLETE_MISSION = 'COMPLETE_MISSION';
// -- #GROUPS --
export const GET_GROUPS = 'GET_GROUPS';
export const GET_PREMIUM_GROUP_DETAIL = 'GET_PREMIUM_GROUP_DETAIL';
export const REMOVE_GROUP = 'REMOVE_GROUP';
export const GET_GROUP = 'GET_GROUP';
export const JOIN_GROUP = 'JOIN_GROUP';
export const LEAVE_GROUP = 'LEAVE_GROUP';
export const GET_ADMIN_GROUP_DATA = 'GET_ADMIN_GROUP_DATA';
// -- GROUPS END --
export const SEARCH_USER = 'SEARCH_USER';
export const SAVE_GROUP = 'SAVE_GROUP';
export const SEND_INVITATION = 'SEND_INVITATION';
export const GET_REFERRALS = 'GET_REFERRALS';
export const GET_NEWS = 'GET_NEWS';
export const GET_COURSES = 'GET_COURSES';
export const GET_BENEFITS = 'GET_BENEFITS';
export const GET_COURSE = 'GET_COURSE';
export const GET_COURSE_TEST = 'GET_COURSE_TEST';
export const CHECK_LESSON = 'CHECK_LESSON';
export const GET_BUDGET = 'GET_BUDGET';
export const SAVE_BUDGET = 'SAVE_BUDGET';
export const GET_MISSIONS = 'GET_MISSIONS';
export const GET_USER_MISSIONS = 'GET_USER_MISSIONS';
export const SAVE_GOAL = 'SAVE_GOAL';
export const GET_PROFILE_DETAIL = 'GET_PROFILE_DETAIL';
export const GET_CATEGORIES = 'GET_CATEGORIES';
export const GET_USER_GROUP_TESTS = 'GET_USER_GROUP_TESTS';
export const GET_COURSES_SHORT = 'GET_COURSES_SHORT';
export const GET_WORLDS = 'GET_WORLDS';
export const SAVE_WORLDS = 'SAVE_WORLDS';
export const UPDATE_WORLD = 'UPDATE_WORLD';
export const GET_NEW_PROFILE = 'GET_NEW_PROFILE';
export const GET_USER_MEDALS = 'GET_USER_MEDALS';
export const GET_USER_SKILLS = 'GET_USER_SKILLS';
export const GET_USER_LEVEL_PROGRESS = 'GET_USER_LEVEL_PROGRESS';
export const GET_MOTIVES = 'GET_MOTIVES';
export const GET_METAS = 'GET_METAS';
export const UPDATE_USER_PROFILE = 'UPDATE_USER_PROFILE';
export const UPDATE_MOTIVES = 'UPDATE_MOTIVES';
export const UPDATE_METAS = 'UPDATE_METAS';
export const SEARCH_USERS = 'SEARCH_USERS';
export const GET_GROUPS_SEARCH_LIST = 'GET_GROUPS_SEARCH_LIST';
export const FILL_USER_MISSIONS = 'FILL_USER_MISSIONS';
export const GET_RANKING = 'GET_RANKING';
export const SEARCH_JOIN_GROUP = 'SEARCH_JOIN_GROUP';
export const SET_ROL = 'SET_ROL';
export const COMPLETE_MISSION = 'COMPLETE_MISSION';
export const SET_SCORE = 'SET_SCORE';
export const GET_CURRENT_STORY = 'GET_CURRENT_STORY';

// CONTROL SECTION
export const GET_DOCENTS_BY_REGION = 'GET_DOCENTS_BY_REGION';
export const GET_STUDENTS_BY_REGION = 'GET_STUDENTS_BY_REGION';
export const GET_DOCENTS_BY_PROVINCE = 'GET_DOCENTS_BY_PROVINCE';
export const GET_STUDENTS_BY_PROVINCE = 'GET_STUDENTS_BY_PROVINCE';
export const GET_DASHBOARD = 'GET_DASHBOARD';

export default {
  GET_NEW_PROFILE,
  UPDATE_WORLD,
  SAVE_WORLDS,
  GET_WORLDS,
  GET_COURSES_SHORT,
  GET_USER_GROUP_TESTS,
  GET_CATEGORIES,
  GET_PROFILE_DETAIL,
  SAVE_GOAL,
  SAVE_BUDGET,
  GET_BUDGET,
  CHECK_LESSON,
  GET_COURSE_TEST,
  GET_COURSE,
  GET_COURSES,
  SAVE_GROUP,
  SEARCH_USER,
  LEAVE_GROUP,
  JOIN_GROUP,
  POST_EMAIL_MARKETPLACE,
  GET_GROUP,
  REMOVE_GROUP,
  GET_GROUPS,
  GET_MISSION,
  DELETE_ACCOUNT,
  LOGIN,
  GET_USERS,
  GET_POSTS,
  GET_COUNTRIES,
  POST_PROFILE,
  POST_ANSWERS,
  UPDATE_PROFILE,
  GET_TEST,
  GET_TEST_RESULT,
  GET_USER,
  UPDATE_USER,
  POST_TEST_QUESTION,
  RECOVER_PASSWORD,
  CHANGE_PASSWORD,
  GET_LEVELS,
  CLEAN_PROFILE_UPDATE,
  POST_NEXT_LEVEL,
  CLEAN_TEST_RESULT_DATA,
  UPLOAD_PICTURE,
  SEND_EMAIL_PRODUCT,
  GET_MISSIONS,
  GET_USER_MISSIONS,
  GET_USER_MEDALS,
  GET_USER_SKILLS,
  GET_USER_LEVEL_PROGRESS,
  GET_MOTIVES,
  GET_METAS,
  UPDATE_USER_PROFILE,
  UPDATE_MOTIVES,
  UPDATE_METAS,
  SEARCH_USERS,
  FILL_USER_MISSIONS,
  GET_RANKING,
  SEARCH_JOIN_GROUP,
  SET_ROL,
  COMPLETE_MISSION,
  SET_SCORE,
  GET_CURRENT_STORY,
  SET_COMPLETED_TUTORIALS,
};
