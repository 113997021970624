exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".styles__avatar__3IY1q {\n  border-radius: 50%;\n  display: inline-block;\n  height: 143px;\n  padding: 6px;\n  position: relative;\n  width: 143px; }\n  .styles__avatar__3IY1q img {\n    border-radius: 50%;\n    height: 100%;\n    width: 100%; }\n\n.styles__edit__1oLwj {\n  max-width: 25px;\n  max-height: 25px;\n  position: relative;\n  bottom: 25px;\n  left: 40px; }\n  @media only screen and (max-width: 424px) {\n    .styles__edit__1oLwj {\n      max-width: 15px;\n      max-height: 15px;\n      position: relative;\n      bottom: 15px;\n      left: 35px; } }\n", ""]);

// exports
exports.locals = {
	"avatar": "styles__avatar__3IY1q",
	"edit": "styles__edit__1oLwj"
};