exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".styles__alertBg__1ffw3 {\n  padding: 20px; }\n\n.styles__alertTitle__tKo9p {\n  font-size: 19px;\n  font-weight: 900;\n  color: var(--gray8); }\n  @media only screen and (min-width: 640px) {\n    .styles__alertTitle__tKo9p {\n      font-size: 30px; } }\n\n.styles__alertDescription__1Euph {\n  font-size: 16px;\n  color: var(--gray8); }\n  @media only screen and (min-width: 640px) {\n    .styles__alertDescription__1Euph {\n      font-size: 25px; } }\n\n.styles__avatar__34EZS {\n  width: 70px;\n  height: 70px;\n  border-radius: 0; }\n\n.styles__containerButtons__3I43t {\n  display: grid;\n  grid-gap: 10px;\n  justify-content: center; }\n\n.styles__middleImg__1jItM {\n  width: 40px;\n  height: 40px; }\n  @media only screen and (min-width: 640px) {\n    .styles__middleImg__1jItM {\n      width: 70px;\n      height: 70px; } }\n", ""]);

// exports
exports.locals = {
	"alertBg": "styles__alertBg__1ffw3",
	"alertTitle": "styles__alertTitle__tKo9p",
	"alertDescription": "styles__alertDescription__1Euph",
	"avatar": "styles__avatar__34EZS",
	"containerButtons": "styles__containerButtons__3I43t",
	"middleImg": "styles__middleImg__1jItM"
};