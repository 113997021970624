import React from 'react';
import Button from '@components/basic/Button';
import SimpleAlert from '@components/complex/SimpleAlert';

import styles from './styles.scss';
import Avatar from '../Avatar';

function NiceAlert({
  customStyleAvatar,
  labelCancel = 'Cancelar',
  labelOk = 'Ok',
  middleImg = false,
  onCancel,
  onOk,
  state,
  title = '¡Listo!',
  topImg = false,
  description,
  showCancel = false,
  color = 'transparent',
}) {
  console.log(color);
  return (
    <SimpleAlert state={state} color={color}>
      {topImg && (
        <Avatar avatarImg={topImg} customStyle={`${styles.avatar} ${customStyleAvatar}`} />
      )}
      <div className={`mb10 ${styles.alertBg}`}>
        <p className={styles.alertTitle}>{title}</p>
      </div>
      {middleImg && (
        <img className={styles.middleImg} src={middleImg} alt="img" />
      )}
      <p className={`mb20 ${styles.alertDescription}`}>{description}</p>
      {onOk && (
        <div className={styles.containerButtons}>
          <Button label={labelOk} onClick={onOk} className="buttonOk" />
          {showCancel && <Button label={labelCancel} className="buttonCancel" onClick={onCancel} />}
        </div>
      )}
    </SimpleAlert>
  );
}

export default NiceAlert;
