/* eslint-disable max-len */
import React, {
  Fragment,
  lazy,
  Suspense,
} from 'react';
import { Switch, Route } from 'react-router-dom';
import PrivateRoute from '@components/complex/PrivateRoute';
import Logout from '@screens/Logout';
import ROUTES from '@constants/routes';
import GoogleAnalytics from '@components/complex/GoogleAnalytics';
import { showRegister } from '@utils/envValidations';
import NiceAlert from '@components/complex/NiceAlert';

import './styles.scss';
import { useInactivityTimeout } from './components/basic/TimeOutHook';
import { IMAGES } from '../assets/images/asobancaria';

const Login = lazy(() => import('@screens/Login/index'));
const Home = lazy(() => import('@screens/Home/index'));
const RecoverPassword = lazy(() => import('@screens/RecoverPassword'));
const ChangePassword = lazy(() => import('@screens/ChangePassword'));
const Register = lazy(() => import('@screens/Register'));
const ProfileCreate = lazy(() => import('@screens/Profile/Create'));
const Profile = lazy(() => import('@screens/Profile'));
const Tos = lazy(() => import('@screens/Tos'));
const Groups = lazy(() => import('./screens/Groups/GroupsContainer'));
const GroupsDetails = lazy(() => import('./screens/GroupsDetails/ContainerGroupsDetails'));
const GroupsCreate = lazy(() => import('./screens/GroupsCreate/GroupCreateContainer'));
const Courses = lazy(() => import('./screens/Courses'));
const CoursesDetails = lazy(() => import('./screens/CoursesDetails'));
const CoursesTest = lazy(() => import('./screens/CoursesTest'));
const Budget = lazy(() => import('./screens/Budget'));
const BudgetCreate = lazy(() => import('./screens/Budget/BudgetCreate'));
const IntroStory = lazy(() => import('./screens/Stories/IntroStory'));
const Story = lazy(() => import('./screens/Stories/index'));
const Admin = lazy(() => import('./screens/Admin/index'));
const Test = lazy(() => import('./screens/Test/index'));
const End = lazy(() => import('./screens/End/index'));

function App() {
  const { isActive, showPopup } = useInactivityTimeout(15 * 60 * 1000, 1 * 60 * 1000);
  return (
    <>
      {showPopup && (
      <NiceAlert
        state={showPopup}
        title="Sesion a punto de expirar por inactividad"
        description="Su sesión caducará en 1 minuto. Si desea extender el tiempo, mueva el mouse."
        topImg={IMAGES.security}
        color="#FFFFFF"
      />
      )}
      <>
        <Fragment>
          <Suspense fallback={<div>.</div>}>
            <Switch>
              <Route path={ROUTES.ROOT} component={Login} exact />
              <Route path={ROUTES.LOGIN} component={Login} />
              <Route path={ROUTES.TOS} component={Tos} exact />
              <Route path={ROUTES.CHANGE_PASSWORD} component={ChangePassword} />
              <Route path={ROUTES.RECOVER_PASSWORD} component={RecoverPassword} />
              {showRegister() ? <Route path={ROUTES.REGISTER} component={Register} /> : null}
              <PrivateRoute path={ROUTES.HOME} component={Home} />
              <PrivateRoute path={ROUTES.PROFILE} component={Profile} exact />
              <PrivateRoute path={ROUTES.PROFILE_CREATE} component={ProfileCreate} />
              <PrivateRoute path={ROUTES.GROUPS} component={Groups} exact />
              <PrivateRoute path="/groups/:id/details" component={GroupsDetails} exact />
              <PrivateRoute path={ROUTES.GROUPS_CREATE} component={GroupsCreate} exact />
              <PrivateRoute path="/groups/:id/edit" component={GroupsCreate} exact />
              <PrivateRoute path={ROUTES.COURSES} component={Courses} exact />
              <PrivateRoute path="/courses/:id/details" component={CoursesDetails} exact />
              <PrivateRoute path="/courses/:id/test" component={CoursesTest} exact />
              <PrivateRoute path={ROUTES.BUDGET} component={Budget} exact />
              <PrivateRoute path={ROUTES.BUDGET_CREATE} component={BudgetCreate} exact />
              <PrivateRoute path="/story/intro/:id" component={IntroStory} exact />
              <PrivateRoute path={ROUTES.STORY} component={Story} exact />
              <PrivateRoute path={ROUTES.ADMIN} component={Admin} exact />
              <PrivateRoute path={ROUTES.TEST} component={Test} exact />
              <PrivateRoute path={ROUTES.END} component={End} exact />
              <Route path={ROUTES.LOGOUT} component={Logout} />
            </Switch>
          </Suspense>
          <GoogleAnalytics />
        </Fragment>
      </>
    </>
  );
}

export default App;
