import { useState, useEffect } from "react";

export const useInactivityTimeout = (timeoutDuration, popupDuration) => {
  const [isActive, setIsActive] = useState(true);
  const [showPopup, setShowPopup] = useState(false);
  const [popupTimer, setPopupTimer] = useState(null);

  useEffect(() => {
    let inactivityTimer = null;

    const resetTimeout = () => {
      setIsActive(true);

      clearTimeout(inactivityTimer);
      inactivityTimer = setTimeout(() => {
        setIsActive(false);
        setShowPopup(true);
        setPopupTimer(
          setTimeout(() => {
            setShowPopup(false);
            window.location.href = '/logout';
            setPopupTimer(null);
          }, popupDuration),
        );
      }, timeoutDuration);
    };

    const handleActivity = () => {
      resetTimeout();

      if (showPopup) {
        setShowPopup(false);
        clearTimeout(popupTimer);
        setPopupTimer(
          setTimeout(() => {
            setShowPopup(false);
            setPopupTimer(null);
          }, popupDuration),
        );
      }
    };

    window.addEventListener("mousemove", handleActivity);
    window.addEventListener("keydown", handleActivity);

    return () => {
      window.removeEventListener("mousemove", handleActivity);
      window.removeEventListener("keydown", handleActivity);
      clearTimeout(inactivityTimer);
      if (popupTimer) {
        clearTimeout(popupTimer);
      }
    };
  }, [timeoutDuration, popupDuration, showPopup, popupTimer]);

  return { isActive, showPopup };
};
